import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/material-ui-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Note: This site is a demo of the new MasumiHayashi.com website which is a work-in-progress and not yet in production.`}</h3>
    <br />
    <h2>{`Welcome to Masumi Hayashi.com!`}</h2>
    <p>{`This website, `}<a parentName="p" {...{
        "href": "http://www.masumihayashi.com"
      }}>{`www.masumihayashi.com`}</a>{`, hosts Professor Hayashi's creative research that deals with the internment of Americans of Japanese ancestry during World War II. This project started with Hayashi's panoramic photo collages which show the remnants of sites of Japanese American Internment camps during World War II and were symbolic of an archeological and historical memory. Professor Hayashi interviewed camp survivors in different areas of the United States and Canada which became part of the artwork installation in galleries and museums. She collected photographs taken by these camp survivors when cameras were contraband. Ms. Hayashi project was a search for a collective memory, having been born in the camps, but having no memories of the camps. It was also a personal pilgrimage. These voices, photo collages, and snapshots have become a project that resonates today as we observe our history today.`}</p>
    <h2><a parentName="h2" {...{
        "href": "http://www.masumimuseum.com"
      }}>{`www.masumimuseum.com`}</a></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      